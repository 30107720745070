import { Component } from '@angular/core'
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms'

import { environment } from '../../../../environments/environment'
import { AuthService } from '../../../common/services/auth.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [AuthService]
})
export class ForgotPasswordComponent {
  form: UntypedFormGroup = this.formBuilder.group({
    email: ['', [Validators.email, Validators.required]]
  })
  isAlt: boolean = environment.isAltVersion

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private flashMessageService: FlashMessageService
  ) {}

  submit() {
    this.authService.sendResetPasswordEmail(this.form.value.email).subscribe(
      () => {
        this.flashMessageService.success(
          'Un email avec un lien de réinitialisation vous a été envoyé. Veuillez consulter votre boite mail.'
        )

        this.form.reset()
      },
      (err) => {
        this.flashMessageService.error(err.error.message)
        this.form.reset()
      }
    )
  }
}
