import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'

import { Paginator } from '../../../common/interfaces/paginator.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { AuthService } from '../../../common/services/auth.service'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { MetaService } from '../../../common/services/meta.service'
import { ResourceService } from '../../../common/services/resource.service'
import { projectDefinition } from '../../project/project.definition'
import { Project } from '../../project/project.interface'
import { userDefinition } from '../user.definition'
import { User } from '../user.interface'

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {
  user: User
  projectPaginator: Paginator<Project>

  selectedTab: string
  isCurrentUser: boolean
  hasBrowseTimeSheetsPermission: boolean
  loadingProjects = false

  viewDate: string
  timeSheetToEdit: string
  canSeeBonuses: boolean
  canSeeReports: boolean
  redirectTo: string

  projectDefinition: ResourceDefinition = projectDefinition

  constructor(
    private activatedRoute: ActivatedRoute,
    private resourceService: ResourceService,
    private breadcrumbService: BreadcrumbService,
    private metaService: MetaService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    combineLatest([
      this.activatedRoute.params,
      this.activatedRoute.queryParams,
      this.activatedRoute.data
    ])
      .pipe(
        map((res) => ({
          params: res[0],
          queryParams: res[1],
          data: res[2]
        }))
      )
      .subscribe(
        (res: {
          params: { id: string }
          queryParams: {
            selectedTab?: string
            viewDate?: string
            timeSheetToEdit?: string
            page?: string
            redirectTo?: string
          }
          data: { isMyself: boolean }
        }) => {
          this.user = null

          this.projectPaginator = null
          this.isCurrentUser = res.data.isMyself

          this.selectedTab = res.queryParams.selectedTab || 'dashboard'
          this.redirectTo = res.queryParams.redirectTo

          if (this.selectedTab === 'timeSheets') {
            this.viewDate = res.queryParams.viewDate
            this.timeSheetToEdit = res.queryParams.timeSheetToEdit
          }

          this.resourceService
            .show('users', this.isCurrentUser ? 'myself' : res.params.id, '', {
              checkBonuses: true
            })
            .then(async (userRes: User) => {
              this.user = userRes

              this.hasBrowseTimeSheetsPermission = await this.authService.can(
                'browseTimeSheets'
              )

              const hasBrowseBonusPermission: boolean =
                await this.authService.can('browseBonuses')

              this.canSeeBonuses =
                this.user.hasBonuses &&
                (hasBrowseBonusPermission || this.isCurrentUser)

              const hasBrowseReportsPermission: boolean =
                await this.authService.can('browseReports')
              const hasBrowseOwnReportsPermission: boolean =
                await this.authService.can('browseOwnReports')

              this.canSeeReports =
                hasBrowseReportsPermission ||
                (this.isCurrentUser && hasBrowseOwnReportsPermission)

              this.loadingProjects = true
              this.resourceService
                .list('projects', {
                  userId: this.user.id,
                  page: res.queryParams.page || 1,
                  orderBy: 'status'
                })
                .then((projectRes: Paginator<Project>) => {
                  this.loadingProjects = false
                  this.projectPaginator = projectRes
                })

              // Set Meta and Breadcrumbs.
              this.breadcrumbService.breadcrumbLinks.next([
                {
                  label: 'Collaborateurs',
                  path: '/collaborateurs'
                },
                {
                  label: this.user.name
                }
              ])
              this.metaService.setTags({
                title: this.user.name,
                path: `/collaborateurs/${this.user.id}`
              })
            })
        }
      )
  }

  onProjectPageChanged(page: number): void {
    const queryParams: Params = {
      page: page.toString(),
      selectedTab: 'projects'
    }
    this.router.navigate(
      [
        `/${userDefinition.path}/${
          this.isCurrentUser ? 'myself' : this.user.id
        }`
      ],
      {
        queryParams,
        queryParamsHandling: 'merge'
      }
    )
  }

  reload(): void {
    this.router.navigate([this.activatedRoute.snapshot.url.join('/')], {
      queryParams: {
        reload: new Date().toISOString()
      },
      queryParamsHandling: 'merge'
    })
  }
}
