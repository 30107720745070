import { Component, OnInit } from '@angular/core'
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { environment } from '../../../../environments/environment'
import { AuthService } from '../../../common/services/auth.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  form: UntypedFormGroup = this.formBuilder.group({
    password: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&~#_^.+:;=])[A-Za-zd$@$!%*?&~#_^.+:;=].{18,}'
        )
      ]
    ],
    token: ['', Validators.required]
  })
  isAlt: boolean = environment.isAltVersion

  constructor(
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private flashMessageService: FlashMessageService,
    private router: Router
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.form.patchValue({
        token: params.token
      })
    })
  }

  submit() {
    this.authService
      .resetPassword(this.form.value.password, this.form.value.token)
      .subscribe(
        () => {
          this.flashMessageService.success(
            `Votre mot de passe a bien été réinitialisé, veuillez vous connecter à l'application`
          )

          return this.router.navigate(['/login'])
        },
        (err) => {
          this.flashMessageService.error(err.error.message)
          this.form.reset()
        }
      )
  }
}
