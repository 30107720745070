<section class="hero is-fullheight">
  <div class="hero-body">
    <div class="container has-text-centered">
      <div class="columns">
        <div class="column col-login">
          <div>
            <figure class="avatar">
              <img src="./assets/img/logo.png" alt="logo" *ngIf="!isAlt" />
              <img src="./assets/img/logo-alt.png" alt="logo" *ngIf="isAlt" />
            </figure>
            <h3 class="title is-2 m-t-gutter m-b-sm">
              Réinitialiser votre<br />
              mot de passe
            </h3>
            <p class="m-b-gutter">Entrez votre nouveau mot de passe.</p>
            <form [formGroup]="form" (submit)="submit()">
              <div class="field m-b-gutter">
                <div class="control">
                  <input
                    type="password"
                    formControlName="password"
                    class="input is-large"
                    placeholder="Nouveau mot de passe*"
                    autocomplete="new-password"
                  />
                  <p class="help">
                    Doit contenir au moins 18 caractères, 1 majuscule, 1
                    miniscule, 1 chiffre et 1 caractère spécial
                  </p>
                </div>
              </div>

              <button
                type="submit"
                [disabled]="form.invalid"
                class="button is-block is-primary is-large is-fullwidth m-b-md"
              >
                Réinitialiser
              </button>
              <a routerLink="/login">Se connecter</a>
            </form>
          </div>
        </div>
        <div class="column col-decoration">
          <div class="drawing">
            <img src="../../../../assets/img/bg-3.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
