export const environment = {
  envName: 'productionAlt',
  isAltVersion: true,
  production: true,
  baseUrl: 'https://zele-sge.nextep-health.com',
  apiBaseUrl: 'https://api.zele-sge.nextep-health.com',
  storagePath: 'https://api.zele-sge.nextep-health.com/storage',
  tokenName: 'zeleAltToken',
  tokenWhitelistedDomains: ['api.zele-sge.nextep-health.com'],
  enableBugsnag: true,
  bugsnagApiKey: 'd11732012ca18ec602ffb0e1823d6aa7'
}
