import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'

import { FlashMessageService } from '../services/flash-message.service'
import { PolicyService } from '../services/policy.service'

@Injectable({
  providedIn: 'root'
})
export class ProjectAbacGuard {
  constructor(
    private flashMessageService: FlashMessageService,
    private policyService: PolicyService
  ) {}

  async canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    const projectId: number = next.params[next.data?.projectIdParamName || 'id']
    const policy: string = next.data.policy

    const hasAccess: boolean = await this.policyService.hasUserPolicyAccess(
      'projects',
      projectId,
      policy
    )

    if (hasAccess) {
      return true
    }

    this.flashMessageService.error(
      `Vous ne pouvez pas réaliser cette action sur ce projet. Veuillez contacter votre administrateur.`
    )

    return false
  }
}
